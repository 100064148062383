<template>
	<v-layout column>
		<v-layout row wrap>
			<v-flex xs12 md12 px-1>
				<w-email-input v-model="accountant.email" :label="$t('email')" required />
			</v-flex>
			<v-flex xs12 sm6 px-1>
				<v-text-field v-model="accountant.firstname" :disabled="!canEditName" :rules="[rules.requiredName]" :label="$t('first_name')"></v-text-field>
			</v-flex>
			<v-flex xs12 sm6 px-1>
				<v-text-field v-model="accountant.lastname" :disabled="!canEditName" :rules="[rules.requiredName]" :label="$t('last_name')"></v-text-field>
			</v-flex>
			<v-flex xs12 md12 px-1>
				<v-select v-model="accountant.role" :items="roles" item-text="name" item-value="key" :label="$t('role.name')"/>
			</v-flex>
			<v-flex xs12 px-1>
				<w-checkbox v-model="allVendors" :label="$t('all_companies')" />
			</v-flex>
			<v-flex xs12 px-1>
				<v-select
					v-model="accountant.vendors"
					:items="clients"
					item-text="company"
					item-value="id"
					:label="$tc('company.company_access', 2)"
					multiple
					:no-data-text="$tc('company.company_access', 0)"
				>
					<template v-slot:selection="{ item, index }">
						<v-chip v-if="index < 3" small>
							<span>{{ item.company }}</span>
						</v-chip>
						<v-chip v-else-if="index == 3 && accountant.vendors.length - index === 1" small>
							<span>({{ $tc('other_count', 1) }})</span>
						</v-chip>
						<v-chip v-else-if="index === 3" small>
							<span>
								(
								{{ $tc('other_count', accountant.vendors.length - index) }}
								)
							</span>
						</v-chip>
					</template>
				</v-select>
			</v-flex>
			<v-flex xs12 px-1>
				<w-checkbox v-model="accountant.send_mail" :label="$t('customers.actions.send_invitation_mail')" />
			</v-flex>
		</v-layout>
		<v-layout justify-end>
			<w-btn :disabled="!inviteTeamMemberIsValid" :loading="loading" @click="addAccountant">
				{{ $t('user.actions.add.collaborator') }}
			</w-btn>
		</v-layout>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManagerUserCreationForm',
	mixins: [TeamManagerModuleGuard],
	props: {
		clients: {
			required: true,
			type: Array
		},
		roles: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			accountant: { send_mail: true, vendors: [] },
			canEditName: false,
			loading: false,
			userAlreadyExist: false,
			rules: {
				required: function (val) {
					let result = true
					if (!val || !val.trim()) {
						result = this.$t('required')
					}
					return result
				}.bind(this),
				requiredName: function (val) {
					let result = true
					if ((!val || !val.toString().trim()) && !this.userAlreadyExist) {
						result = this.$t('required')
					}
					return result
				}.bind(this),
				email: function (val) {
					let result = true
					if (!val || !val.trim() || !this.isEmailValid(val)) {
						result = this.$t('invalid_email')
					}
					return result
				}.bind(this)
			}
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected
		}),
		allVendors: {
			get: function () {
				return this.accountant.vendors.length === this.clients.length
			},
			set: function (value) {
				if (value) {
					this.clients.forEach(vendor => {
						if (!this.accountant.vendors.includes(vendor.id)) {
							this.accountant.vendors.push(vendor.id)
						}
					})
				} else {
					this.accountant.vendors.splice(0, this.accountant.vendors.length)
				}
			}
		},
		inviteTeamMemberIsValid: function () {
			let result = true
			if (
				!this.accountant.email ||
				!this.accountant.email.trim() ||
				!this.isEmailValid(this.accountant.email) ||
				(!this.userAlreadyExist && !this.accountant.firstname) ||
				(!this.userAlreadyExist && !this.accountant.firstname.trim()) ||
				(!this.userAlreadyExist && !this.accountant.lastname) ||
				(!this.userAlreadyExist && !this.accountant.lastname.trim()) ||
				!this.accountant.role
			) {
				result = false
			}
			return result
		},
		isGroupAdmin: function () {
			return this.accountingFirm ? this.accountingFirm.isGroupAdmin : false
		}
	},
	watch: {
		'accountant.email': {
			handler: function (val) {
				if (val && val.trim() && this.isEmailValid(val)) {
					this.getUserInfoByEmail(val)
				} else {
					if (this.userAlreadyExist) {
						Vue.set(this.accountant, 'firstname', '')
						Vue.set(this.accountant, 'lastname', '')
					}
					this.canEditName = false
					this.userAlreadyExist = false
				}
			}
		}
	},
	methods: {
		isEmailValid (email) {
			return email
				.trim()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		},
		getUserInfoByEmail (email) {
			this.service
				.getUserByEmail(email, { show_error: false })
				.then(userInfo => {
					if (userInfo) {
						this.userAlreadyExist = true
						this.canEditName = false
						Vue.set(this.accountant, 'firstname', userInfo.firstname)
						Vue.set(this.accountant, 'lastname', userInfo.lastname)
					} else {
						if (this.userAlreadyExist) {
							Vue.set(this.accountant, 'firstname', '')
							Vue.set(this.accountant, 'lastname', '')
						}
						this.userAlreadyExist = false
						this.canEditName = true
					}
				})
		},
		addAccountant: function () {
			this.loading = true
			this.service
				.addAccountant(this.accountingFirm.id, this.accountant)
				.then(user => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('accounting_firms.messages.teammate_invited'))
					this.accountant = { send_mail: true, vendors: [] }
					this.$emit('saved', {
						...user,
						id: user.user_id,
						role_id: {
							role_id: user.role_id
						}
					})
				})
				.catch(err => {
					const errorCode = err.response?.data?.error?.code

					if (errorCode == 'accounting-firm-users.client-user') {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('accounting_firms.messages.teammate_invited_vendor_user_error'))
					} else if (errorCode == 'accounting-firm-users.duplicate') {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('accounting_firms.messages.teammate_invited_already_exist_error'))
					} else {
						this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, this.$t('accounting_firms.messages.teammate_invited_error'))
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
	}
}
</script>
